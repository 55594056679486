import React, { FC, useState } from "react";
import Fractional from "../../../assets/Fractional.svg";
import { marketStore } from "entities/market";
import { useStore } from "zustand";
import "./style.scss";

type PropsType = {
  onClick?: () => void;
};

const FractionalStatusIndicator: FC<PropsType> = ({ onClick }) => {
  const { assetInfo } = useStore(marketStore);

  const isFractional = assetInfo?.decimal_places ? true : false;

  if (!isFractional) {
    return null;
  }

  return (
    <div onClick={onClick} className="fractional-status-indicator">
      <img src={Fractional} alt="Fractional" />
    </div>
  );
};

export default FractionalStatusIndicator;
