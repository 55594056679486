import React, { FC } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import Fractional from "../../../assets/Fractional.svg";
type PropsType = {
  onClose: () => void;
};

const FractionalStatusBottomSheet: FC<PropsType> = ({ onClose }) => {
  const { t, i18n } = useTranslation("app");

  return (
    <div
      className="bottom-sheet-modal-wrapper"
      style={{
        justifyContent: "center",
      }}
    >
      <div onClick={onClose} className="outlet-fractional-status-modal"></div>

      <div className="fractional-status">
        <div className="fractional-status-header">
          <div className="fractional-status-symbol">
            <img src={Fractional} alt="Fractional" />
          </div>
          <p>{t("fractionalTrading")}</p>
        </div>
        <p className="status-info">
          {t("fractionalTradingInfo")} <b>{t("fractionalTradingInfo2")}</b>
        </p>
      </div>
    </div>
  );
};

export default FractionalStatusBottomSheet;
